import React from 'react'
import { AboutWrapper } from './Styled'
import AboutValues from './AboutValues'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { ContactButton } from '../../Styled/CustomElements'
import { Box, HeadingStyled, LazyImage, MainHeading, TextStyled } from '../../Styled/Elements'
import { AboutHouse2, AboutHouseDesign, AboutInstagram, AboutLink, AboutTwitter, ButtonIcon, ThemeDesign } from '../../Styled/AllImages'
import { useInView } from 'react-intersection-observer'
import { Animate } from 'react-simple-animate'


const About = () => {
    const { t, i18n } = useTranslation();
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    const isRTL = i18n.dir() === 'rtl';
    const start = isRTL
        ? { opacity: 0, transform: 'translateX(350px)' }
        : { opacity: 0, transform: 'translateX(-350px)' };

    const end = { opacity: 1, transform: 'translateX(0)' };
    const WhatsApp = () => {
        const phoneNumber = '+966580911057';
        const message = 'Hello, Advanced Housing';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    const socialIcon = [
        {
            img: <LazyImage src={AboutTwitter} />
        },
        {
            img: <LazyImage src={AboutInstagram} />
        },
        {
            img: <LazyImage src={AboutLink} />
        },
    ]

    return (
        <AboutWrapper id='about'>
            <Container>
                <Row>
                    <Col>
                        <Box ref={ref} className='common-space common-space-over artical-main about-content mb-0 '>
                            <Animate
                                play={inView}
                                duration={1}
                                start={start}
                                end={end}
                            >
                                <Box className='w-50 about-animate'>
                                    <LazyImage src={ThemeDesign} />
                                    <HeadingStyled className='heading-mini' color='#DBB12A' spacing='2px' >{t('WelcomeTo')}</HeadingStyled>
                                    <MainHeading className='heading-main' color='var(--themeColor)'>{t('Advanced_Housing')}</MainHeading>
                                    <TextStyled>{t('real_estate')}</TextStyled>
                                    <ContactButton className='header-btn' icon={ButtonIcon} label={t('Contact_us')} onClick={WhatsApp} />
                                </Box>
                            </Animate>

                            <Box bg='var(--bgColor2)' className='about-house '>
                                <LazyImage className='layer-mid-3' src={AboutHouseDesign} />
                            </Box>
                            <Box className='house-bird '>
                                <LazyImage className='layer-mid-2' src={AboutHouse2} />
                            </Box>
                            <Box className='about-social-link'>
                                {
                                    socialIcon.map((list, key) => (
                                        <Box key={key}>
                                            {list.img}
                                        </Box>
                                    ))
                                }
                            </Box>
                        </Box>
                    </Col>
                </Row>
            </Container >
            <AboutValues />
        </AboutWrapper >
    )
}

export default About