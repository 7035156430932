import { Link } from 'react-scroll'
import React, { useEffect } from 'react'
import { BannerWrapper } from './Styled'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Box, HeadingStyled, LazyImage, MainHeading } from '../../Styled/Elements'
import { BannerBg, DownBtn, Instagram, Linkedin, ParallexBg, Twitter } from '../../Styled/AllImages'

const Banner = () => {
    const { t } = useTranslation();
    useEffect(() => {
        window.addEventListener('scroll', () => {
            let scrollY = window.scrollY;
            document.documentElement.style.setProperty("--scrollTop", `${scrollY}px`);
        });
    }, []);

    const socialIcon = [
        {
            img: <Twitter />
        },
        {
            img: <Instagram />
        },
        {
            img: <Linkedin />
        },
    ]
    return (
        <BannerWrapper className='' id='home'>
            <Row>
                <Col className='position-relative'>
                    <Box className='header-main'>
                        <Box className='layers'>
                            <Box className='common-space layer-head'>
                                <HeadingStyled className='caption' color='var(--whiteColor)' spacing='4px' lh='1'>{t('Crafting_architectural')}</HeadingStyled>
                                <HeadingStyled className='caption' color='var(--whiteColor)' spacing='4px' lh='1'>{t('marvels')}</HeadingStyled>
                                <MainHeading className='text-styled' spacing='10px' size='337px' color='var(--whiteColor)' lh='1'>{t('Riyadh')}</MainHeading>
                            </Box>
                            <Box className='img-layer layer-base' style={{ background: `url(${BannerBg})` }}></Box>
                            <Box className='img-layer layer-mid' style={{ background: `url(${ParallexBg})` }}></Box>
                        </Box>
                    </Box>
                    <Box className='down-btn'>
                        <Link to='about' spy={true} smooth={true} offset={-40} duration={500} activeClass="active">
                            <LazyImage className='layer-mid-up' src={DownBtn} />
                        </Link>
                    </Box>
                    <Box className='social-link'>
                        {
                            socialIcon.map((list, key) => (
                                <Box key={key}>
                                    {list.img}
                                </Box>
                            ))
                        }
                    </Box>
                </Col>
            </Row>
        </BannerWrapper>
    )
}

export default Banner