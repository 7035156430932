import React from 'react'
import ContactForm from './ContactForm'
import Footer from '../../footer/Footer'
import { ContactWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { TextWithIcon } from '../../Styled/CustomElements'
import { Box, LazyImage, Span, SpanStyled } from '../../Styled/Elements'
import { ContactInstagram, ContactLinkedin, ContactTwitter, Email, Phone, ThemeDesignRight, location } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'

const Contact = () => {
  const Map = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3624.0534996534766!2d46.790105!3d24.725043499999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f00deeca92ab1%3A0xf4034cc39bf8d148!2zMjY4Ny0yNzA3INin2YTZgti12LHYjCDYp9mE2YXZhtin2LHYjCDYp9mE2LHZitin2LYgMTQyMjHYjCDYp9mE2LPYudmI2K_Zitip!5e0!3m2!1sar!2s!4v1701073706025!5m2!1sar!2s"
  const { t } = useTranslation();
  return (
    <ContactWrapper className='contact-main-bg' id='contact'>
      <Span className='contact-theme contact-left'>
        <LazyImage src={ThemeDesignRight} />
      </Span>
      <Span className='contact-theme contact-right'>
        <LazyImage src={ThemeDesignRight} />
      </Span>
      <Container>
        <Row>
          <Col>
            <Box className='contact-wrapper contact-wrap-bg'>
              <Box className='contect-with-us'>
                <Box className='d-flex flex-column'>
                  <SpanStyled size='24px' color='var(--themeColor)' transform='math-auto'>{t('Connect_with_Us')}</SpanStyled>
                  <SpanStyled size='24px' color='var(--themeColor)' transform='math-auto'>{t('Start_the_Conversation')}</SpanStyled>
                </Box>
                <ContactForm />
              </Box>
              <Box className='contect-with-you'>
                <SpanStyled size='24px' color='var(--themeColor)' transform='math-auto'>{t('We_are_pleased')}</SpanStyled>
                <Box className='d-flex flex-column gap-3'>
                  <TextWithIcon headingSize='14px' className='contact-text' icon={Phone} title='+966580911057' />
                  <TextWithIcon headingSize='14px' icon={Email} title='sales.ahouse@gmail.com' />
                  <TextWithIcon icon={location} desc={t('location')} />
                </Box>
                <Box className='contact-social-links d-flex align-items-center  gap-3'>
                  <ContactInstagram />
                  <ContactLinkedin />
                  <ContactTwitter />
                </Box>
                <Box>
                  <iframe src={Map} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </Box>
              </Box>
            </Box>
          </Col>
        </Row>
      </Container>
      <Footer />
    </ContactWrapper>
  )
}

export default Contact