import { LazyImage } from "../Styled/Elements"
import { CLientImg1, CLientImg2, CLientImg3, CLientImg4, CLientImg5, Eye, Mission, Priject1, Priject2, Priject3, Priject4, Priject5, Priject6, Priject7, Priject8, Project1, Project2, ProjectImg, ServiceTick } from "../Styled/AllImages"


export const NavDataConfig = [
    {
        to: 'home',
        label: 'Home',
    },
    {
        to: 'about',
        label: 'About',
    },
    {
        to: 'services',
        label: 'Services',
    },
    {
        to: 'projects',
        label: 'Projects',
    },
    {
        to: 'partner',
        label: 'Partner',
    },
    {
        to: 'contact',
        label: 'contactUs',
    },
]

export const AboutDataList = [
    {
        img: <LazyImage src={Eye} />,
        heading: "Our_Vision",
        paragraph: "Our_Vision_content"
    },
    {
        img: <LazyImage src={Mission} />,
        heading: "Our_Mission",
        paragraph: "Our_Mission_content"
    },
]
export const achieveData = [
    {
        heading: '50,000%',
        text: "Square_meters",
    },
    {
        heading: '40+',
        text: "Total_residential",
    },
    {
        heading: '30M+',
        text: "Total_project_value",
    },
    {
        heading: '32+',
        text: "Total_number_of_projects",
    },
]
export const clientItems = [
    {
        img: <LazyImage src={CLientImg1} />,
    },
    {
        img: <LazyImage src={CLientImg2} />,
    },
    {
        img: <LazyImage src={CLientImg3} />,
    },
    {
        img: <LazyImage src={CLientImg4} />,
    },
    {
        img: <LazyImage src={CLientImg5} />,
    },
    {
        img: <LazyImage src={CLientImg1} />,
    },

]

export const tabs = [
    {
        id: 'AlManarProject1',
        title: 'Al_Manar_Project',
        feet: '1000 Sq Feet',
        text: 'Our_Projects_content1',
        img: <LazyImage src={Project1} />,

    },
    {
        id: 'AlManarProject2',
        title: 'Al_Manar_Project_2',
        feet: '1000 Sq Feet',
        text: 'Our_Projects_content2',
        img: <LazyImage src={Project1} />,
    },
    {
        id: 'AndalusProject',
        title: 'Andalus_Project',
        feet: '200 Sq Feet',
        text: 'Andalus_Project_content',
        img: <LazyImage src={Project2} />,
    },
    // {
    //     id: 'AlRamalProject',
    //     title: 'Al_Ramal_Project',
    //     feet: '100 Sq Feet',
    //     text: 'Our_Projects_content1',
    //     img: <LazyImage src={ProjectImg} />,
    // },
    // {
    //     id: 'KingFaisalProject',
    //     title: 'King_Faisal_Project',
    //     feet: '5500 Sq Feet',
    //     text: 'Our_Projects_content1',
    //     img: <LazyImage src={ProjectImg} />,
    // },
    // {
    //     id: 'MuannasiahProject',
    //     title: 'Mu_annasiah_Project',
    //     feet: '9060 Sq Feet',
    //     text: 'Our_Projects_content1',
    //     img: <LazyImage src={ProjectImg} />,
    // },
    // {
    //     id: 'QadisiyahProject',
    //     title: 'Qadisiyah_Project',
    //     feet: '700 Sq Feet',
    //     text: 'Our_Projects_content1',
    //     img: <LazyImage src={ProjectImg} />,
    // },
    // {
    //     id: 'RawdahProject',
    //     title: 'Rawdah_Project',
    //     feet: '500 Sq Feet',
    //     text: 'Our_Projects_content1',
    //     img: <LazyImage src={ProjectImg} />,
    // },

];

export const serviceListData = [
    {
        icon: <ServiceTick />,
        heading: "Property_development",
    },
    {
        icon: <ServiceTick />,
        heading: "Sales_and_marketing",
    },
    {
        icon: <ServiceTick />,
        heading: "Construction_and_contracting",
    },
    {
        icon: <ServiceTick />,
        heading: "Real_estate_consultancy",
    },
]