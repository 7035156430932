import React from 'react'
import { AboutValueWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { AboutDataList } from '../../data/Data'
import { Box, LazyImage, Span, SpanStyled, TextStyled } from '../../Styled/Elements'
import { Eye, Mission, ThemeDesignRight, Tick, Value } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'
import { Animate, AnimateGroup } from 'react-simple-animate'
import { useInView } from 'react-intersection-observer';

const AboutValues = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    const { t } = useTranslation();
    return (
        <AboutValueWrapper className='about-bg'>
            <Span className='about-theme-design'>
                <LazyImage src={ThemeDesignRight} />
            </Span>
            <Container>
                <Row>
                    <Col>
                        <Box className='common-space common-space-over'>
                            <Box ref={ref} className='d-flex align-itens-center justify-content-between a-values'>
                                <AnimateGroup play={inView} >
                                    < Box className='w-25'>
                                        <Animate

                                            delay={0.5}
                                            sequenceIndex={0}
                                            start={{ opacity: 0, transform: "translateY(-15px)" }}
                                            end={{ opacity: 1, transform: "translateY(0)" }}
                                        >
                                            <Box className='d-flex flex-column align-itens-center gap-2'>
                                                <LazyImage src={Eye} />
                                                <SpanStyled color='var(--darkBlue)'> {t('Our_Vision')}</SpanStyled>
                                                <TextStyled>{t('Our_Vision_content')}</TextStyled>
                                            </Box>
                                        </Animate>
                                    </Box>

                                    < Box className='w-25'>
                                        <Animate
                                            delay={0.3}
                                            sequenceIndex={1}
                                            start={{ opacity: 0, transform: "translateY(-15px)" }}
                                            end={{ opacity: 1, transform: "translateY(0)" }}
                                        >
                                            <Box className='d-flex flex-column align-itens-center gap-2'>
                                                <LazyImage src={Mission} />
                                                <SpanStyled color='var(--darkBlue)'> {t('Our_Mission')}</SpanStyled>
                                                <TextStyled>{t('Our_Mission_content')}</TextStyled>
                                            </Box>
                                        </Animate>
                                    </Box>

                                    <Box className='w-25'>
                                        <Animate
                                            delay={0.3}
                                            sequenceIndex={2}
                                            start={{ opacity: 0, transform: "translateY(-15px)" }}
                                            end={{ opacity: 1, transform: "translateY(0)" }}
                                        >
                                            <Box className='d-flex flex-column align-itens-center gap-2'>
                                                <LazyImage src={Value} />
                                                <SpanStyled color='var(--darkBlue)'>{t('Our_value')}</SpanStyled>
                                                <Box className='d-flex align-items-center justify-content-between flex-wrap o-value'>
                                                    <Box className='d-flex align-items-baseline gap-2 '>
                                                        <Tick />
                                                        <TextStyled color='var(--textGrey)'>{t('Credibility')}</TextStyled>
                                                    </Box>
                                                    <Box className='d-flex align-items-baseline gap-2 '>
                                                        <Tick />
                                                        <TextStyled color='var(--textGrey)'>{t('Responsibility')}</TextStyled>
                                                    </Box>
                                                    <Box className='d-flex align-items-baseline gap-2 '>
                                                        <Tick />
                                                        <TextStyled color='var(--textGrey)'>{t('Quality')}</TextStyled>
                                                    </Box>
                                                    <Box className='d-flex align-items-baseline gap-2'>
                                                        <Tick />
                                                        <TextStyled color='var(--textGrey)'>{t('Innovation')}</TextStyled>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Animate>
                                    </Box>
                                </AnimateGroup>
                            </Box>
                        </Box>
                    </Col>
                </Row>
            </Container >
        </AboutValueWrapper >
    )
}

export default AboutValues