import styled from "styled-components";

export const ServiceWrapper = styled.section`
.service-content-wrapper{
    position:relative;
    padding: 80px 0 140px;
    display: flex;
    align-items: baseline;
    justify-content: space-between; 
}
.sale-service-content{
    
    width: 32%;
}
.service-content{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.service-boy{
    position: absolute;
    right: 0;
    left: 0;
    bottom: -2px;
    margin: auto;
    width: fit-content;
    perspective: 1000px;
    &>span{
        width: 405px;
    }
}
.sale-content-scroll{
    height: 205px;
    overflow-y: auto;
}
.service-boy-animate{
    transform-style: preserve-3d;
    animation: flipAnimation 3.5s infinite alternate;
}
@keyframes flipAnimation {
    15% {
        transform: scaleX(1);
    }
    100% {
        transform: scaleX(-1);
    }
}
`
export const AchivementWrapper = styled.div`
.achived-content-wrapper{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width:575px;
    position: relative;
    right: 0px;
}

.achieve-box{
    width: 270px;
    height:165px;
    padding: 20px 22px;
    background: transparent;
    backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: 0px 5px 12px 0px rgba(172,131,34,0.3);
    -webkit-box-shadow: 0px 5px 12px 0px rgba(172,131,34,0.3);
    -moz-box-shadow: 0px 5px 12px 0px rgba(172,131,34,0.3);
}
.achieve-bird{
    position: absolute;
    top: -50px;
    left: -150px;
    right: 0;
    /* margin: auto; */
    width: 140px;
    height: 140px;
}
`