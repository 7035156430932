import styled from "styled-components";

export const AboutWrapper = styled.section`
background-color: var(--bgColor2);
.about-content{
    border: 1px solid var(--themeColor);
    border-radius: 10px;
    padding: 80px 60px;
    width:85%;
    
}
.about-house{
    height: 320px;
    width: 410px;
    position: absolute;
    right: -320px;
    bottom: 20px;
    top: 0;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.house-bird{
    position: absolute;
    right: 220px;
    left: 0;
    bottom: -35px;
    margin: auto;
    width: 430px;
    
}
.about-social-link{
    position: absolute;
    top: 0px;
    bottom: 0;
    left: -18px;
    margin: auto;
    height: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: var(--bgColor2);
    // cursor:pointer;
    & img{
        cursor:pointer;
    }
}
`

export const AboutValueWrapper = styled.section` 
.common-space{
    padding:50px 0 150px;
}
.about-theme-design{
    position: absolute;
    right: 0;
    top: -70px;
}
`