import React from 'react'
import Slider from 'react-slick'
import { ClientWrapper } from './Styled'
import { clientItems } from '../../data/Data'
import { Col, Container, Row } from 'react-bootstrap'
import { ContactButton } from '../../Styled/CustomElements'
import { Box, LazyImage, MainHeading, TextStyled } from '../../Styled/Elements'
import { ButtonIcon, ThemeDesign, LeftArrow, RightArrow, ClientRightArrow, ClientLeftArrow } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'

const Client = () => {
    const { t } = useTranslation();
    const WhatsApp = () => {
        const phoneNumber = '+966580911057';
        const message = 'Hello, Advanced Housing';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        swipeToSlide: true,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 1500,
        pauseOnHover: false,
        prevArrow: <ClientLeftArrow />,
        nextArrow: < ClientRightArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <ClientWrapper className='client-bg' id='partner'>
            <Container className=''>
                <Row>
                    <Col>
                        <Box className='gap-3 client-content'>
                            <LazyImage src={ThemeDesign} />
                            <MainHeading className='darkTextGradient heading-main'>{t('Our_Success_Partners')}</MainHeading>
                            <TextStyled color='var(--whiteColor)'>{t('Our_Success_Partners_content')}</TextStyled>
                            <ContactButton icon={ButtonIcon} label={t('Contact_us')} onClick={WhatsApp} />
                        </Box>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Box className='common-space'>
                            <Slider {...settings}>
                                {clientItems.map((item, index) => (
                                    <Box key={index} className='common-space client-slider'>
                                        {item.img}
                                    </Box>
                                ))}
                            </Slider>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </ClientWrapper>
    )
}

export default Client