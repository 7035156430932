import styled from "styled-components";

export const ClientWrapper = styled.section`
.client-content{
    display: flex !important;
    flex-direction:column;
    align-items: start; 
    gap:20px;
    width:40%;
}
.client-slider{
    display: inline-flex !important;
    align-items: center; 
    justify-content: center;
}
.slick-prev, .slick-next{
    width:60px;
    height:60px;
    top:45%;
}
.slick-prev{
    left:-65px;
}
.slick-next{
    right:-65px;
}

`
export const ClientOpportunityWrapper = styled.section`
.client-opp-boy{
    position: relative;
    bottom: 80px;
}
`