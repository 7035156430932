import React from 'react'
import { ServiceWrapper } from './Styled'
import ServiceAchive from './ServiceAchive'
import { serviceListData } from '../../data/Data'
import { Container, Row, } from 'react-bootstrap'
import { ContactButton } from '../../Styled/CustomElements'
import { ButtonIcon, ServiceBoy } from '../../Styled/AllImages'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer';
import { Animate } from 'react-simple-animate'

const Service = () => {
    const { t, i18n } = useTranslation();
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    // Determine text direction (RTL or LTR)
    const isRTL = i18n.dir() === 'rtl';

    // Define styles based on text direction
    const start1 = isRTL
        ? { opacity: 0, transform: 'translateX(350px)' }
        : { opacity: 0, transform: 'translateX(-350px)' };
    const start2 = isRTL
        ? { opacity: 0, transform: 'translateX(-350px)' }
        : { opacity: 0, transform: 'translateX(350px)' };

    const end = { opacity: 1, transform: 'translateX(0)' };

    const WhatsApp = () => {
        const phoneNumber = '+966580911057';
        const message = 'Hello, Advanced Housing';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    return (
        <>
            <ServiceWrapper className='service-bg common-space' id='services'>
                <Container>
                    <Row>
                        <Box ref={ref} className='service-content-wrapper'>
                            <Box className='sale-service-content'>
                                <Animate
                                    play={inView}
                                    duration={1.5}
                                    delay={0.5}
                                    start={start1}
                                    end={end}
                                >
                                    <Box className='service-content'>
                                        <MainHeading className='lightTextGradient'>{t('Sales_Services')}</MainHeading>
                                        <TextStyled color='var(--whiteColor)'>{t('Sales_Services_content')}</TextStyled>
                                        <Box className='service-list d-flex flex-column gap-2'>
                                            {
                                                serviceListData.map((list, key) => (
                                                    <Box key={key} className='whiteColor d-flex gap-2 '>
                                                        {list.icon}
                                                        <SpanStyled size='16px' transform="math-auto">{t(list.heading)}</SpanStyled>
                                                    </Box>
                                                ))
                                            }
                                        </Box>
                                        <ContactButton icon={ButtonIcon} label={t('Contact_us')} onClick={WhatsApp} />
                                    </Box>
                                </Animate>
                            </Box>

                            <Box className='service-boy'>
                                <LazyImage className='service-boy-animate' src={ServiceBoy} />
                            </Box>
                            <Box className='sale-service-content'>
                                <Animate
                                    play={inView}
                                    duration={1.5}
                                    delay={0.5}
                                    start={start2}
                                    end={end}
                                >
                                    <Box clasName='service-content'>
                                        <MainHeading className='lightTextGradient'>{t('After_Sales_Services')}</MainHeading>
                                        <Box className='sale-content-scroll mb-3'>
                                            <TextStyled color='var(--whiteColor)'>{t('After_Sales_Services_content')}</TextStyled>
                                            <SpanStyled size='20px' color='var(--whiteColor)'>{t('Company_Warranty')}</SpanStyled>
                                            <TextStyled color='var(--whiteColor)'>{t('Company_Warranty_content')}</TextStyled>
                                            <SpanStyled size='20px' color='var(--whiteColor)'>{t('Maintenance_Contracts')}</SpanStyled>
                                            <TextStyled color='var(--whiteColor)'>{t('Maintenance_Contracts_content')}</TextStyled>
                                        </Box>
                                        <ContactButton icon={ButtonIcon} label={t('Contact_us')} onClick={WhatsApp} />
                                    </Box>
                                </Animate>
                            </Box>
                        </Box>
                    </Row>
                </Container>
            </ServiceWrapper >
            <ServiceAchive />
        </>
    )
}

export default Service