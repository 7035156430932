
import About from "../components/sections/about/About"
import Banner from "../components/sections/banner/Banner"
import Client from "../components/sections/client/Client"
import Contact from "../components/sections/contact/Contact"
import Service from "../components/sections/services/Service"
import Projects from "../components/sections/project/Projects"
import ClientOpportunity from "../components/sections/client/ClientOpportunity"

const Index = () => {
    return (
        <>
            <Banner />
            <About />
            <Service />
            <Projects />
            <Client />
            <ClientOpportunity />
            <Contact />
        </>
    )
}

export default Index