import styled from "styled-components";

export const ContactWrapper = styled.section`
.contact-wrapper{
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    position: relative;
    bottom: 80px;
    padding:60px 60px 0;
}
.contect-with-us{
    width:50%;
}
.contect-with-you{
    width:50%;
    display: flex;
    flex-direction: column;
    gap:25px;
}
.contact-theme{
    position: absolute;
    height: 225px;
    top: -100px;
    bottom: 0px;
    margin: auto;
    z-index: 1;
}
.contact-right{
    right: 0;
}
.contact-left{
    left: 0;
    -webkit-transform: scale(-1);
    -moz-transform: scale(-1);
    -ms-transform: scale(-1);
    -o-transform: scale(-1);
}
    
`
export const ContactFormWrapper = styled.div`
    width:90%;
.field-box{
    display: flex;
    flex-direction: column;
    gap:8px;
    margin:20px 0;
}
.input-field{
    padding: 10px 15px;
    border-radius: 10px;
    border: none;
    outline: none;
    background: white;
    box-shadow: 0px 0px 9px 0px rgba(152,111,31,0.2);
    -webkit-box-shadow: 0px 0px 9px 0px rgba(152,111,31,0.2);
    -moz-box-shadow: 0px 0px 9px 0px rgba(152,111,31,0.2);
}
.error-message{
    font-family:var(--arRegular);
    // border:1px solid red;
    color:red;
    padding:6px 8px;
    border-radius:8px;
    background-color: #ff000014;
}
.loading-btn{
    position: absolute;
    left: 0;
    right: 0;
    width: 50%;
    height: 20%;
    z-index: 10;
    background: #0b0b0b6b;
    text-align: center;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color:var(--whiteColor);
    font-family:var(--regular);

}
.contact-social-links{
    position: relative;
    left: 15px;
}
`