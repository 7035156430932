import React from 'react'
import { AchivementWrapper } from './Styled'
import { achieveData } from '../../data/Data'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { AcieveBird, ThemeDesign } from '../../Styled/AllImages'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../Styled/Elements'
import { Animate } from 'react-simple-animate'
import { useInView } from 'react-intersection-observer';

const ServiceAchive = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    const { t, i18n } = useTranslation();

    const isRTL = i18n.dir() === 'rtl';

    // Define styles based on text direction
    const start1 = isRTL
        ? { opacity: 0, transform: 'translateX(-450px)' }
        : { opacity: 0, transform: 'translateX(450px)' };

    const end = { opacity: 1, transform: 'translateX(0)' };


    return (
        <AchivementWrapper className=' common-space achieve-bg'>
            <Container>
                <Row>
                    <Col className='d-flex justify-content-end'>

                        <Box ref={ref} className='common-space achived-content-wrapper'>
                            <Animate
                                play={inView}
                                duration={1.5}
                                delay={0.5}
                                start={start1}
                                end={end}
                            >
                                <LazyImage className='achieve-bird' src={AcieveBird} />
                                <LazyImage src={ThemeDesign} />
                                <MainHeading className='darkTextGradient heading-main'>{t('Our_achievements')}</MainHeading>
                                <Box className='d-flex flex-wrap gap-4'>
                                    {
                                        achieveData.map((list, key) => (
                                            <Box key={key} className='achieve-box'>
                                                <SpanStyled size='32px'>{list.heading}</SpanStyled>
                                                <TextStyled>{t(list.text)}</TextStyled>
                                            </Box>
                                        ))
                                    }
                                </Box>
                            </Animate>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </AchivementWrapper>
    )
}

export default ServiceAchive