import { tabs } from '../../data/Data'
import { ProjectWrapper } from './Styled'
import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ContactButton } from '../../Styled/CustomElements'
import { ButtonIcon, LeftArrow, ProjectLayer, RightArrow, ThemeDesign, ThemeDesignRight } from '../../Styled/AllImages'
import { Box, LazyImage, MainHeading, Span, SpanStyled, TextStyled } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import { Animate, AnimateKeyframes } from 'react-simple-animate'
import { useInView } from 'react-intersection-observer';

const Projects = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    const { t } = useTranslation();
    const WhatsApp = () => {
        const phoneNumber = '+966580911057';
        const message = 'Hello, Advanced Housing';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    // const keyframesData = [
    //     { opacity: 0, transform: 'scale(0.8)', translate: '-50%, -50%' },
    //     { opacity: 1, transform: 'scale(1)', translate: '-50%, -50%' },
    // ];


    const [activeTab, setActiveTab] = useState(tabs[0]);
    const [randomKey, setRandomKey] = useState(0);

    const [animate, setAnimate] = useState(true);
    const handleTabClick = (clickedTab) => {
        setActiveTab(clickedTab);
        setAnimate(true);
    };


    const tabRef = useRef(null);
    const [scrollDirection, setScrollDirection] = useState(null);
    const scrollTab = (direction) => {
        setScrollDirection(direction);
        const container = tabRef.current;
        const scrollAmount = 200;

        if (direction === 'left') {
            container.scrollLeft -= scrollAmount;
        } else {
            container.scrollLeft += scrollAmount;
        }
    };
    return (
        <ProjectWrapper id='projects'>
            <Span className='project-theme-design'>
                <LazyImage src={ThemeDesignRight} />
            </Span>
            <Container>
                <Row>
                    <Col>
                        <Box className='common-space d-flex flex-column align-items-center justify-content-center gap-3'>
                            <LazyImage src={ThemeDesign} />
                            <MainHeading className='darkTextGradient heading-main'>{t('Our_Projects')}</MainHeading>
                        </Box>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Box className='py-3 tab-container common-space'>
                            <LeftArrow className='arrows' size='30px' onClick={() => scrollTab('left')} />
                            <Box className='tab-content' ref={tabRef}>
                                {tabs.map((item, key) => (
                                    <li className={`tabs-item ${item.id === activeTab.id ? "active" : ""}`} onClick={() => { handleTabClick(item); setRandomKey(Math.random() * 10000) }} key={item.id}>
                                        <SpanStyled className="px-2">{t(item.title)}</SpanStyled>
                                    </li>
                                ))}
                            </Box>
                            <RightArrow className='arrows' size='30px' onClick={() => scrollTab('right')} />
                        </Box>
                        <Box ref={ref} key={randomKey}>
                            <Animate
                                play={animate && inView}
                                duration={0.5}
                                delay={0.4}
                                start={{ opacity: 0, transform: 'scale(0.8)', translate: '-50%, -50%' }}
                                end={{ opacity: 1, transform: 'scale(1)', translate: '-50%, -50%' }}
                            >
                                <Box className={`position-relative common-space`}>
                                    <Box className='Tab-box'>
                                        {activeTab.img}
                                        <Box className='tab-box-content'>
                                            <SpanStyled className='border-bottom-line ' size='24px' color='var(--themeColor)' transform='math-auto'>{t(activeTab.title)}</SpanStyled>
                                            <TextStyled className='border-bottom-line'>{t(activeTab.feet)}</TextStyled>
                                            <TextStyled>{t(activeTab.text)}</TextStyled>
                                            <ContactButton icon={ButtonIcon} label={t('Contact_us')} onClick={WhatsApp} />
                                        </Box>
                                    </Box>
                                    <Box className='project-layer mt-1'>
                                        <LazyImage src={ProjectLayer} />
                                    </Box>
                                </Box>
                            </Animate>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </ProjectWrapper >
    )
}

export default Projects