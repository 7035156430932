import React from 'react'
import { ClientOpportunityWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { ContactButton } from '../../Styled/CustomElements'
import { ButtonIcon, ClientBoy2 } from '../../Styled/AllImages'
import { Box, LazyImage, SpanStyled, TextStyled } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'

const ClientOpportunity = () => {
    const { t } = useTranslation();
    const WhatsApp = () => {
        const phoneNumber = '+966580911057';
        const message = 'Hello, Advanced Housing';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    return (
        <ClientOpportunityWrapper className='client-bg-2'>
            <Container>
                <Row>
                    <Col>
                        <Box className='opp-box d-flex align-items-center justify-content-between'>
                            <Box className='client-opp-boy'>
                                <LazyImage src={ClientBoy2} />
                            </Box>
                            <Box className='opp-text d-flex flex-column align-items-start justify-content-between ga-4'>
                                <SpanStyled className='client-heading' size='40px' color='var(--whiteColor)'>{t('Your_Future')}</SpanStyled>
                                <SpanStyled className='client-heading' size='40px' color='var(--whiteColor)'>{t('Your_Opportunity')}</SpanStyled>
                                <TextStyled color='var(--whiteColor)' >{t('Your_Future_join_us')}</TextStyled>
                                <ContactButton icon={ButtonIcon} label={t('Contact_us')} onClick={WhatsApp} />
                            </Box>
                        </Box>
                    </Col>
                </Row>
            </Container>

        </ClientOpportunityWrapper>
    )
}

export default ClientOpportunity