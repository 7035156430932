import styled from "styled-components";

export const ProjectWrapper = styled.section`
position:relative;
.tab-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--darkBlue);
    border-top: 1px solid var(--darkBlue);
    gap:15px;
    &>svg{
        width: 52px;
        height: 45px;
    }
}
.tab-content{
    display: flex;
    align-items: center;
    overflow-x: auto;
    white-space: nowrap;
    transition: scroll-left 0.4s ease-in;
    gap:15px;
    &::-webkit-scrollbar {
        display:none;
    }
}
.tabs-item{
    padding: 8px 15px;
    border-radius: 8px;
    cursor: pointer;
    &:hover{
        background: var(--darkBlue);
        color:var(--whiteColor);
    } 
    &.active{
        border-radius: 8px;
        background: var(--buttonColor);
        color:var(--whiteColor);
    }
}
.Tab-box{
    position:relative;
    display: flex;
    // align-items: center;
    border-radius: 10px;
    border: 1px solid goldenrod;
    background: var(--lightText);
    width: 90%;
    height:100%;
    margin: auto;
    &>span{
        min-width: 60%;
        height: 500px;
        display:flex;
    }
}
.Tab-box .custom-img{
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    width:100%;
    height:100%;
    // object-fit:cover;
}
.tab-box-content{
    width: 40%;
    padding: 70px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.project-layer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.project-theme-design{
    position: absolute;
    top: -100px;
    transform: scale(-1);
    -webkit-transform: scale(-1);
    -moz-transform: scale(-1);
    -ms-transform: scale(-1);
    -o-transform: scale(-1);
}
.border-bottom-line:before{
    background-color:#E3E3E3;
    width:100%;
    bottom:-5px;
}

`
export const ProjectContentWrapper = styled.section`
`
